<template>
  <div class="v-popup-head v-title">
    <div class="v-message-popup-title">
      <span
        v-if="title"
        v-html="sanitize(title)"
      />
    </div>
  </div>

  <div class="v-popup-body">
    <v-input
      class-name="v-arora-input"
      required
      v-form-validator="{
        Form: 'login',
        Value: inputText,
        Required: true,
        Validations: ['length'],
        MaxLength: 255
      }"
      :data-test-id="dataTestId && `${dataTestId}-input`"
      :label="sanitize(title)"
      v-model:text="inputText"
    />
    <div class="v-popup-buttons-block">
      <arora-button
        class-name="btn-yes"
        :data-test-id="dataTestId && `${dataTestId}-button`"
        :label="translate('messagePopup.ok')"
        @click="close"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { popupInputProps } from '~types/popupStore'

import { vFormValidator, VInput } from '@arora/common'

const { initialText, onConfirm } = defineProps<popupInputProps>()
const { translate, sanitize } = useI18nSanitized()
const popupStore = usePopupStore()

const inputText = ref<string>(initialText)

function close(): void {
  onConfirm(inputText.value)
  popupStore.closePopup()
}
</script>

<style lang="scss">
@use '~/assets/variables';

.v-popup-content {
  .v-popup-body {
    .v-popup-buttons-block {
      text-align: center;
      margin-top: 20px;
      justify-content: center;

      .btn-yes {
        background: variables.$PrimaryBackgroundColor;
        color: variables.$PrimaryColor;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
</style>
